/*
 *
 * lv1_0_1    lv1代表关卡1, 0,0代表坐标
 */

export const levelList = {
  lv1_0_0:
    "https://sudiaoba.oss-cn-beijing.aliyuncs.com/assets/images/fragment/lv1_0_0.png",
  lv1_0_1:
    "https://sudiaoba.oss-cn-beijing.aliyuncs.com/assets/images/fragment/lv1_0_1.png",
  lv1_1_0:
    "https://sudiaoba.oss-cn-beijing.aliyuncs.com/assets/images/fragment/lv1_1_0.png",
  lv1_1_1:
    "https://sudiaoba.oss-cn-beijing.aliyuncs.com/assets/images/fragment/lv1_1_1.png",
  lv2_0_0:
    "https://sudiaoba.oss-cn-beijing.aliyuncs.com/assets/images/fragment/lv2_0_0.png",
  lv2_0_1:
    "https://sudiaoba.oss-cn-beijing.aliyuncs.com/assets/images/fragment/lv2_0_1.png",
  lv2_1_0:
    "https://sudiaoba.oss-cn-beijing.aliyuncs.com/assets/images/fragment/lv2_1_0.png",
  lv2_1_1:
    "https://sudiaoba.oss-cn-beijing.aliyuncs.com/assets/images/fragment/lv2_1_1.png",
  lv2_2_0:
    "https://sudiaoba.oss-cn-beijing.aliyuncs.com/assets/images/fragment/lv2_2_0.png",
  lv3_0_0:
    "https://sudiaoba.oss-cn-beijing.aliyuncs.com/assets/images/fragment/lv3_0_0.png",
  lv3_0_1:
    "https://sudiaoba.oss-cn-beijing.aliyuncs.com/assets/images/fragment/lv3_0_1.png",
  lv3_1_0:
    "https://sudiaoba.oss-cn-beijing.aliyuncs.com/assets/images/fragment/lv3_1_0.png",
  lv3_1_1:
    "https://sudiaoba.oss-cn-beijing.aliyuncs.com/assets/images/fragment/lv3_1_1.png",
  lv3_2_0:
    "https://sudiaoba.oss-cn-beijing.aliyuncs.com/assets/images/fragment/lv3_2_0.png",
  lv3_2_1:
    "https://sudiaoba.oss-cn-beijing.aliyuncs.com/assets/images/fragment/lv3_2_1.png",
  lv4_0_0:
    "https://sudiaoba.oss-cn-beijing.aliyuncs.com/assets/images/fragment/lv4_0_0.png",
  lv4_0_1:
    "https://sudiaoba.oss-cn-beijing.aliyuncs.com/assets/images/fragment/lv4_0_1.png",
  lv4_0_2:
    "https://sudiaoba.oss-cn-beijing.aliyuncs.com/assets/images/fragment/lv4_0_2.png",
  lv4_1_0:
    "https://sudiaoba.oss-cn-beijing.aliyuncs.com/assets/images/fragment/lv4_1_0.png",
  lv4_1_1:
    "https://sudiaoba.oss-cn-beijing.aliyuncs.com/assets/images/fragment/lv4_1_1.png",
  lv4_1_2:
    "https://sudiaoba.oss-cn-beijing.aliyuncs.com/assets/images/fragment/lv4_1_2.png",
  lv4_2_0:
    "https://sudiaoba.oss-cn-beijing.aliyuncs.com/assets/images/fragment/lv4_2_0.png",
  lv5_0_0:
    "https://sudiaoba.oss-cn-beijing.aliyuncs.com/assets/images/fragment/lv5_0_0.png",
  lv5_0_1:
    "https://sudiaoba.oss-cn-beijing.aliyuncs.com/assets/images/fragment/lv5_0_1.png",
  lv5_0_2:
    "https://sudiaoba.oss-cn-beijing.aliyuncs.com/assets/images/fragment/lv5_0_2.png",
  lv5_1_0:
    "https://sudiaoba.oss-cn-beijing.aliyuncs.com/assets/images/fragment/lv5_1_0.png",
  lv5_1_1:
    "https://sudiaoba.oss-cn-beijing.aliyuncs.com/assets/images/fragment/lv5_1_1.png",
  lv5_1_2:
    "https://sudiaoba.oss-cn-beijing.aliyuncs.com/assets/images/fragment/lv5_1_2.png",
  lv5_2_0:
    "https://sudiaoba.oss-cn-beijing.aliyuncs.com/assets/images/fragment/lv5_2_0.png",
  lv5_2_1:
    "https://sudiaoba.oss-cn-beijing.aliyuncs.com/assets/images/fragment/lv5_2_1.png",
  lv6_0_0:
    "https://sudiaoba.oss-cn-beijing.aliyuncs.com/assets/images/fragment/lv6_0_0.png",
  lv6_0_1:
    "https://sudiaoba.oss-cn-beijing.aliyuncs.com/assets/images/fragment/lv6_0_1.png",
  lv6_0_2:
    "https://sudiaoba.oss-cn-beijing.aliyuncs.com/assets/images/fragment/lv6_0_2.png",
  lv6_1_0:
    "https://sudiaoba.oss-cn-beijing.aliyuncs.com/assets/images/fragment/lv6_1_0.png",
  lv6_1_1:
    "https://sudiaoba.oss-cn-beijing.aliyuncs.com/assets/images/fragment/lv6_1_1.png",
  lv6_1_2:
    "https://sudiaoba.oss-cn-beijing.aliyuncs.com/assets/images/fragment/lv6_1_2.png",
  lv6_2_0:
    "https://sudiaoba.oss-cn-beijing.aliyuncs.com/assets/images/fragment/lv6_2_0.png",
  lv6_2_1:
    "https://sudiaoba.oss-cn-beijing.aliyuncs.com/assets/images/fragment/lv6_2_1.png",
  lv6_2_2:
    "https://sudiaoba.oss-cn-beijing.aliyuncs.com/assets/images/fragment/lv6_2_2.png"
};
